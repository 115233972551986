import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";

import {
    CurrentUserContext,
    ATTR_RECEIVE_NEWS,
    ATTR_ACCEPTED_TERMS_TIME,
} from "../providers/auth";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Loading from "../components/loading";
import AcceptTnCBox from "../components/acceptTnCBox";
import SignInAgain from "../components/sign-in-again";

export default function Account() {
    return (
        <Layout>
            <Router>
                <AccountPage path="account" />
            </Router>
        </Layout>
    );
}

const AccountPage = ({ location }) => {
    const currentUser = useContext(CurrentUserContext);
    const [view, setView] = useState(<Loading />);

    useEffect(() => {
        window.sessionStorage.setItem(
            "redirectAfterLogin",
            decodeURIComponent(location.href)
        );
        currentUser.getIdToken().then((idToken) => {
            if (idToken) {
                currentUser
                    .currentAuthenticatedUser()
                    .then((user) => {
                        setView(
                            <AccountBox
                                cpasUser={user.attributes || {}}
                                username={user.username || ""}
                            />
                        );
                    })
                    .catch((err) => setView(<AccountError error={err} />));
            } else {
                setView(<SignInAgain nextPath={location.href} />);
            }
        });
    }, [currentUser, location]);

    return (
        <>
            <Seo title="My Account" />
            {view}
        </>
    );
};

const AccountBox = ({ cpasUser, username }) => {
    const [
        EMAIL,
        GIVEN_NAME,
        FAMILY_NAME,
        PHONE,
        TITLE,
        ORG,
        JOB_TITLE,
        RECEIVE_NEWS,
        ACCEPTED_TERMS_TIME,
    ] = [
        "email",
        "given_name",
        "family_name",
        "phone_number",
        "custom:title",
        "custom:organization",
        "custom:job_title",
        ATTR_RECEIVE_NEWS, // "custom:receive_news"
        ATTR_ACCEPTED_TERMS_TIME, // "custom:agreement_at"
    ];
    const props = [
        GIVEN_NAME,
        FAMILY_NAME,
        PHONE,
        TITLE,
        ORG,
        JOB_TITLE,
        RECEIVE_NEWS,
        ACCEPTED_TERMS_TIME,
    ];

    const getUserAttrs = (cpasUser) => {
        // Only update attributes appears in props
        let attrs = {};
        props.forEach((prop) => {
            attrs[prop] = cpasUser[prop] || "";
        });
        attrs[RECEIVE_NEWS] = attrs[RECEIVE_NEWS] || "0";
        return attrs;
    };

    const currentUser = useContext(CurrentUserContext);
    const [oldUser, setOldUser] = useState(getUserAttrs(cpasUser));
    const [user, setUser] = useState(oldUser);
    const [userChange, setUserChange] = useState(false);
    const [pageError, setPageError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [askConfirm, setAskConfirm] = useState(false);

    // Phone format: E.164 format (+<Country code><Phone number>)
    const phoneFormat = new RegExp(/^\+[1-9]\d{1,14}$/);
    const validatePhone = () => {
        const phone = user[PHONE];
        setPhoneError(phone && !phone.match(phoneFormat));
    };

    const setUserAttr = (attrName, event) => {
        if (props.indexOf(attrName) === -1) {
            return;
        }
        setUserAttrByValue(attrName, event.target.value);
    };

    const setUserAttrByValue = (attrName, value) => {
        let newUser = Object.assign({}, user);
        newUser[attrName] = value;
        setUser(newUser);
        checkUserChange(oldUser, newUser);
    };

    const checkUserChange = (oldUser, newUser) => {
        for (let i = 0; i < props.length; i++) {
            const prop = props[i];
            if (oldUser[prop] !== newUser[prop]) {
                setUserChange(true);
                return;
            }
        }
        if (userChange) {
            setUserChange(false);
        }
    };

    const confirmSave = () => {
        if (phoneError) {
            return false;
        }
        setAskConfirm(true);
    };

    const save = () => {
        setAskConfirm(false);
        setIsSaving(true);
        currentUser
            .updateUserAttributes(currentUser.getUser(), user)
            .then(() => {
                return currentUser.currentAuthenticatedUser();
            })
            .then((newUser) => {
                const urlParam = new URLSearchParams(window.location.search);
                if (urlParam.get("redirect") === "true") {
                    navigate("/");
                }
                setUser(getUserAttrs(newUser.attributes));
                setOldUser(getUserAttrs(newUser.attributes));
                setUserChange(false);
                setPageError(null);
            })
            .catch((err) => setPageError(err))
            .finally(() => setIsSaving(false));
    };

    const reset = () => {
        setUser(oldUser);
        setUserChange(false);
        setPhoneError(false);
        setPageError(null);
    };

    const updateAcceptTermsTime = () => {
        const userUpdated = {
            ...user,
            [ACCEPTED_TERMS_TIME]: new Date().toISOString(),
        };
        currentUser.updateUserAttributes(currentUser.getUser(), userUpdated);
        setOldUser(userUpdated);
        setUser(userUpdated);
    };

    if (!!!user[ACCEPTED_TERMS_TIME]) {
        return (
            <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                <div
                    className="absolute inset-0 w-screen h-screen bg-white bg-opacity-30 z-5"
                    id="overlay"
                />
                <AcceptTnCBox acceptAction={updateAcceptTermsTime} />
            </div>
        );
    } else {
        return (
            <>
                <div className="flex flex-row items-stretch bg-grey-light h-12">
                    <div className="flex flex-row items-center flex-1 ml-4">
                        My Account
                    </div>
                </div>

                <section className="flex flex-col items-center my-8 md:my-16 mx-4">
                    <div className="md:flex md:flex-col bg-lightgreen1 px-4 md:px-8 py-8 rounded-lg text-sm md:w-4/5 lg:w-3/5">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-2/3 flex flex-col pr-6 pb-5">
                                <label className="pb-1 mr-4" htmlFor="title">
                                    Title
                                </label>
                                <div>
                                    <select
                                        name="title"
                                        className="round-input h-6 pt-px"
                                        value={user[TITLE]}
                                        onBlur={() => true}
                                        onChange={(e) => setUserAttr(TITLE, e)}
                                    >
                                        <option value="" hidden>
                                            ---
                                        </option>
                                        <option value="Dr">Dr</option>
                                        <option value="Prof">Prof</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mrs">Mrs</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:pr-2 pb-5">
                                <label className="mb-1" htmlFor="firstName">
                                    First Name
                                </label>
                                <div>
                                    <input
                                        name="firstName"
                                        className="round-input h-6"
                                        type="text"
                                        value={user[GIVEN_NAME]}
                                        onChange={(e) =>
                                            setUserAttr(GIVEN_NAME, e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:pl-2 pb-5">
                                <label className="mb-1" htmlFor="surname">
                                    Surname
                                </label>
                                <div>
                                    <input
                                        name="surname"
                                        className="round-input h-6"
                                        type="text"
                                        value={user[FAMILY_NAME]}
                                        onChange={(e) =>
                                            setUserAttr(FAMILY_NAME, e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <label htmlFor="email">Email</label>
                        <div className="mt-1 mb-4">
                            <div name="email" className="py-1 px-3 h-6">
                                {cpasUser[EMAIL]}
                            </div>
                        </div>
                        <label htmlFor="phone">Phone Number</label>
                        <div className="mt-1 mb-4">
                            <input
                                name="phone"
                                className="round-input h-6"
                                type="text"
                                value={user[PHONE]}
                                placeholder="Format: +<Country Code><Phone Number>. E.g. +85226556000"
                                onChange={(e) => setUserAttr(PHONE, e)}
                                onBlur={validatePhone}
                            />
                            {phoneError ? (
                                <div className="mt-1 inline-err">
                                    Invalid phone number format.
                                </div>
                            ) : null}
                        </div>
                        <label htmlFor="organization">
                            Affiliated Organization (Institute / Company)
                        </label>
                        <div className="mt-1 mb-4">
                            <input
                                name="organization"
                                className="round-input h-6"
                                type="text"
                                value={user[ORG]}
                                onChange={(e) => setUserAttr(ORG, e)}
                            />
                        </div>
                        <label htmlFor="jobTitle">Job Title</label>
                        <div className="mt-1 mb-4">
                            <input
                                name="jobTitle"
                                className="round-input h-6"
                                type="text"
                                value={user[JOB_TITLE]}
                                onChange={(e) => setUserAttr(JOB_TITLE, e)}
                            />
                        </div>
                        <label className="text-xs mt-1 mb-4 flex flex-row items-start">
                            <input
                                className="mt-1 mr-2 leading-tight"
                                type="checkbox"
                                checked={
                                    user[RECEIVE_NEWS] === "1" ? true : false
                                }
                                onChange={() => {
                                    setUserAttrByValue(
                                        RECEIVE_NEWS,
                                        user[RECEIVE_NEWS] === "0" ? "1" : "0"
                                    );
                                }}
                            />
                            <div>
                                I would like to receive news and offers from
                                ClusterTech via email (e.g. special offers,
                                product updates, news related to events,
                                services and solutions etc.)
                                <div className="mt-1">
                                    Notes: ClusterTech will not disclose your
                                    email address to unauthorised third parties.
                                </div>
                            </div>
                        </label>

                        <div className="py-4">
                            {pageError ? (
                                <div className="inline-err mb-2">
                                    Cannot update account: {pageError.message}
                                </div>
                            ) : null}
                            <div className="flex flex-row">
                                {username && !username.includes("Google") ? (
                                    <button
                                        className="primary btn mb-5"
                                        aria-label="change password"
                                        onClick={() =>
                                            navigate("/change-password")
                                        }
                                    >
                                        Change Password
                                    </button>
                                ) : null}
                            </div>
                            <div className="flex flex-row">
                                <button
                                    className="primary btn w-24"
                                    aria-label="save account details"
                                    disabled={!userChange || isSaving}
                                    onClick={confirmSave}
                                    title={
                                        !userChange
                                            ? "No change to the account details yet."
                                            : ""
                                    }
                                >
                                    {isSaving ? (
                                        <i className="fas fa-spinner fa-spin fa-fw"></i>
                                    ) : null}
                                    Save
                                </button>
                                <button
                                    className="ml-2 plain btn w-24"
                                    aria-label="reset"
                                    onClick={reset}
                                    disabled={isSaving}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmDialog
                    askConfirm={askConfirm}
                    save={save}
                    cancel={() => setAskConfirm(false)}
                />
            </>
        );
    }
};

const AccountError = () => {
    return (
        <>
            <div className="flex flex-row items-stretch bg-grey-light h-12">
                <div className="flex flex-row items-center flex-1 ml-4">
                    My Account
                </div>
            </div>
            <section className="flex flex-col items-center my-8 md:my-16 mx-4">
                <div className="text-red">
                    Cannot load user. Please try again.
                </div>
            </section>
        </>
    );
};

const ConfirmDialog = ({ save, askConfirm, cancel }) => {
    return (
        <div className={askConfirm ? "block" : "hidden"}>
            <div className="fixed inset-0 z-1000 w-full h-screen flex flex-row items-center justify-around bg-black opacity-75"></div>
            <div className="fixed inset-0 z-1000 w-full h-screen flex flex-row items-center justify-around">
                <div className="border-grey-darkest shadow-lg rounded md:w-1/3 mx-4 md:mx-0">
                    <div className="bg-green1 rounded-t">
                        <h3 className="inline-block p-2 text-white text-lg">
                            Confirmation
                        </h3>
                    </div>
                    <div className="bg-white py-4 px-8 rounded-b">
                        <div>
                            <div>
                                Are you sure to save your account details?
                            </div>
                            <div className="mt-4 flex flex-row items-center justify-around">
                                <div>
                                    <button
                                        className="primary btn w-24"
                                        aria-label="Are you sure to save your account details?"
                                        onClick={save}
                                    >
                                        Save
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="plain btn w-24"
                                        aria-label="cancel"
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
